import styled from "@emotion/styled";
import { blueGrey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";

import Section from "../../../components/Section";

const Container = styled.div`
  text-align: center;
`;
const ProjectSectionOne = () => {
  return (
    <Section backgroundColor={blueGrey["800"]} padding="5">
      <Container>
        <Typography variant="h4" component="h1" sx={{ color: "white" }}>
          Our Projects
        </Typography>
        <br />
        <Typography
          variant="h6"
          component="p"
          sx={{ color: "white" }}
          gutterBottom
        >
          Carefully executed portfolio
        </Typography>
      </Container>
    </Section>
  );
};

export default ProjectSectionOne;
