import styled from "@emotion/styled";
import Masonry from "@mui/lab/Masonry";
import { CardActionArea } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { blueGrey as cardColor, cyan as bgColor } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";

import Section from "../../../components/Section";
import { itemData } from "./projects.data";

const BoxContainer = styled(Box)`
  display: flex;
  justify-content: center;
  min-height: 377px;
`;

const CardContentContainer = styled(CardContent)`
  margin-top: -61px;
  position: relative;
  padding: 30px 0px 4px 16px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 212, 255, 0) 82%
  );
`;

const MoreInfo = (props: any) => {
  const { open, handleClose, project } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >
      <DialogTitle sx={{ textAlign: "center" }}>{project?.title}</DialogTitle>
      <DialogContent>
        <ImageList sx={{ width: "100%" }} cols={1} gap={8}>
          {project.pictures?.map((item: any, index: number) => (
            <ImageListItem key={`${item.alt}${index}`}>
              <img src={`${item.img}`} alt={item.alt} loading="lazy" />
            </ImageListItem>
          ))}
          <div />
        </ImageList>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
const ProjectSectionTwo = () => {
  const [open, setOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState({});

  const handleClickOpen = (item: any) => {
    setCurrentProject(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Section backgroundColor={bgColor["800"]} padding="5" containerSize="lg">
      <BoxContainer>
        <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
          {itemData.map((item, index) => (
            <Card
              sx={{ backgroundColor: cardColor["800"] }}
              key={index}
              onClick={() => handleClickOpen(item)}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  src={item.img}
                  alt={item.alt}
                  loading="lazy"
                  sx={{
                    display: "block",
                    width: "100%",
                  }}
                />
                <CardContentContainer>
                  <Typography
                    sx={{ fontWeight: 900 }}
                    variant="subtitle1"
                    component="span"
                  >
                    {item.title}
                  </Typography>
                </CardContentContainer>
              </CardActionArea>
            </Card>
          ))}
        </Masonry>
      </BoxContainer>
      <MoreInfo
        handleClose={handleClose}
        open={open}
        project={currentProject}
      />
    </Section>
  );
};

export default ProjectSectionTwo;
