import p1a from "../../../assets/projects/p1/1.jpg";
import p1main from "../../../assets/projects/p1/2.jpeg";
import p1c from "../../../assets/projects/p1/3.jpg";
// p2
import p2a from "../../../assets/projects/p2/a.jpg";
import p2b from "../../../assets/projects/p2/a1.jpg";
import p2c from "../../../assets/projects/p2/a2.jpg";
import p2d from "../../../assets/projects/p2/a3.jpg";
import p2e from "../../../assets/projects/p2/a4.jpg";
import p2main from "../../../assets/projects/p2/b2.jpg";
import p2f from "../../../assets/projects/p2/b3.jpg";
import p2g from "../../../assets/projects/p2/c.jpg";
import p2h from "../../../assets/projects/p2/c1.jpg";
import p2i from "../../../assets/projects/p2/c2.jpg";
import p2j from "../../../assets/projects/p2/c4.jpg";
import p2k from "../../../assets/projects/p2/d1.jpg";
import p2l from "../../../assets/projects/p2/d2.jpg";
// p3
import center1 from "../../../assets/projects/p3/center1.jpg";
import center2 from "../../../assets/projects/p3/center2.jpg";
import center3 from "../../../assets/projects/p3/center3.jpeg";
import center4 from "../../../assets/projects/p3/center4.jpeg";
import center5 from "../../../assets/projects/p3/center5.jpeg";
import center6 from "../../../assets/projects/p3/center6.jpeg";
import left1 from "../../../assets/projects/p3/left1.jpg";
import left2 from "../../../assets/projects/p3/left2.jpg";
import left3 from "../../../assets/projects/p3/left3.jpg";
// import right1 from "../../../assets/projects/p3/right1.mp4";
// import right2 from "../../../assets/projects/p3/right2.mp4";
// import right3 from "../../../assets/projects/p3/right3.mp4";
// p4
import p4a from "../../../assets/projects/p4/Artboard1.jpg";
import p4b from "../../../assets/projects/p4/Artboard3.jpg";
// p5
import p5_1 from "../../../assets/projects/p5/a1.jpg";
import p5_2 from "../../../assets/projects/p5/a2.jpg";
import p5_3 from "../../../assets/projects/p5/a3.jpg";
import p5_4 from "../../../assets/projects/p5/Artboard8.jpg";
import p5_5 from "../../../assets/projects/p5/b1.jpg";
import p5_6 from "../../../assets/projects/p5/b2.jpg";
import p5_7 from "../../../assets/projects/p5/c1.jpg";
import p5_8 from "../../../assets/projects/p5/c2.jpg";
import p5_9 from "../../../assets/projects/p5/d1.jpg";
import p5_10 from "../../../assets/projects/p5/d2.jpg";
// p6
import p6_a1 from "../../../assets/projects/p6/1.0.jpg";
import p6_a2 from "../../../assets/projects/p6/1.2.jpg";
import p6_b1 from "../../../assets/projects/p6/2.1.jpg";
import p6_b2 from "../../../assets/projects/p6/2.2.jpg";
import p6_b3 from "../../../assets/projects/p6/2.3.jpg";
import p6_b4 from "../../../assets/projects/p6/2.4.jpg";
import p6_b5 from "../../../assets/projects/p6/2.5.jpg";
import p6_b6 from "../../../assets/projects/p6/2.6.jpg";
import p6_c1 from "../../../assets/projects/p6/3.0.jpg";
import p6_c2 from "../../../assets/projects/p6/3.1.jpg";
import p6_c3 from "../../../assets/projects/p6/3.2.jpg";
import p6_c4 from "../../../assets/projects/p6/3.3.jpg";
// p7
import p7_1 from "../../../assets/projects/p7/r3b.jpg";
// p8
import p8_1 from "../../../assets/projects/p8/r1c.jpg";
// p9
import p9_1 from "../../../assets/projects/p9/r2b.jpg";
// p10
import p10_1 from "../../../assets/projects/p10/aFinal.jpg";

export const itemData = [
  {
    img: p1main,
    alt: "corner view 3D render of the residential house",
    title: "The Indulge",
    pictures: [
      { img: p1main, alt: "corner view 3D render of residential house" },
      { img: p1a, alt: "front view 3D render of the residential house" },
      { img: p1c, alt: "side view 3D render of residential house" },
    ],
  },
  {
    img: p2f,
    alt: "Front view 3D render of the new 'RK sweets' bakery",
    title: "A Glacé",
    pictures: [
      {
        img: p2f,
        alt: "Front view 3D render of the new 'RK sweets' bakery",
      },
      { img: p2k, alt: "" },
      { img: p2l, alt: "" },
      { img: p2a, alt: "Sweets aisle - Interior rendered view of Bakery" },
      { img: p2b, alt: "Entrance display - Interior rendered view of Bakery" },
      { img: p2c, alt: "Different angle view of Entrance display in Bakery" },
      { img: p2d, alt: "Sitting area inside the bakery view" },
      { img: p2e, alt: "Sitting area inside the bakery different angle view" },
      { img: p2main, alt: "Alternate front view 3D render of the bakery" },
      { img: p2g, alt: "" },
      { img: p2h, alt: "" },
      { img: p2i, alt: "" },
      { img: p2j, alt: "" },
    ],
  },
  {
    img: center5,
    alt: "House interior 3D render",
    title: "The Panache",
    pictures: [
      { img: center5, alt: "" },
      { img: center6, alt: "" },
      { img: center1, alt: "" },
      { img: center2, alt: "" },
      { img: center3, alt: "" },
      { img: center4, alt: "" },
    ],
  },
  {
    img: left1,
    alt: "Bedroom interior 3D render",
    title: "The Panache - Room",
    pictures: [
      { img: left1, alt: "" },
      { img: left2, alt: "" },
      { img: left3, alt: "" },
    ],
  },
  {
    img: p4a,
    alt: "Bedroom interior 3D render",
    title: "Iron or brick?",
    pictures: [
      { img: p4a, alt: "" },
      { img: p4b, alt: "" },
    ],
  },
  {
    img: p5_1,
    alt: "Office interior - reception area",
    title: "The Delineation",
    pictures: [
      { img: p5_1, alt: "" },
      { img: p5_2, alt: "" },
      { img: p5_3, alt: "" },
      { img: p5_4, alt: "" },
      { img: p5_5, alt: "" },
      { img: p5_6, alt: "" },
      { img: p5_7, alt: "" },
      { img: p5_8, alt: "" },
      { img: p5_9, alt: "" },
      { img: p5_10, alt: "" },
    ],
  },
  {
    img: p6_a1,
    alt: "bedroom interior",
    title: "The Suave - uno",
    pictures: [
      { img: p6_a1, alt: "" },
      { img: p6_a2, alt: "" },
    ],
  },
  {
    img: p6_b4,
    alt: "bedroom interior",
    title: "The Suave - due",
    pictures: [
      { img: p6_b4, alt: "" },
      { img: p6_b5, alt: "" },
      { img: p6_b6, alt: "" },
      { img: p6_b1, alt: "" },
      { img: p6_b2, alt: "" },
      { img: p6_b3, alt: "" },
    ],
  },
  {
    img: p6_c2,
    alt: "bedroom interior",
    title: "The Suave - tre",
    pictures: [
      { img: p6_c2, alt: "" },
      { img: p6_c1, alt: "" },
      { img: p6_c4, alt: "" },
      { img: p6_c3, alt: "" },
    ],
  },
  {
    img: p7_1,
    alt: "bedroom interior",
    title: "Elegant and minimal",
    pictures: [{ img: p7_1, alt: "" }],
  },
  {
    img: p8_1,
    alt: "bedroom interior",
    title: "House-o-rama",
    pictures: [{ img: p8_1, alt: "" }],
  },
  {
    img: p9_1,
    alt: "bedroom interior",
    title: "Camera da letto",
    pictures: [{ img: p9_1, alt: "" }],
  },
  {
    img: p10_1,
    alt: "bedroom interior",
    title: "An Aristocratic",
    pictures: [{ img: p10_1, alt: "" }],
  },
];
