import "./Projects.css";

import ProjectSectionOne from "./sections/ProjectSectionOne";
import ProjectSectionTwo from "./sections/ProjectSectionTwo";

const Projects = () => {
  return (
    <div>
      <ProjectSectionOne />
      <ProjectSectionTwo />
    </div>
  );
};

export default Projects;
